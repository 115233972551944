import { Link, useNavigate } from "react-router-dom"
import CardBody from "../../components/card/card-body.component"
import Card from "../../components/card/card.component"
import TemplateContent from "../../components/template-content/template-content.component"
import Page from "../../layouts/page/page.component"
import { useDispatch, useSelector } from "react-redux"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { getCustomer } from '../../fetch/customers.fetch'
import { removeUser } from "../../store/user/user.action"

const CustomerTemplates = () => {
	const user = useSelector(state => state.user)
	const [ customer, setCustomer ] = useState([]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// ----------------------------------------------------------
	// Fetching user information
	// ----------------------------------------------------------
	const queryCustomer = useQuery({
		queryKey: ['customer', user.info.id], 
		queryFn: () => getCustomer(user.token, user.info.id),
		onSuccess: (res) => {
			if ( res.status === 401 )
				dispatch(removeUser());

			setCustomer(res.data);
		},
		onError: (error) => {
			if ( error.response.status === 401 )
				dispatch(removeUser());
			else navigate('/customers')
		}
	});

	return (
		<Page>
			<div className="row">
				<div className="col-sm-12 col-lg-12">
					<div className="row h-100 mb-5">
						{ queryCustomer.isSuccess && queryCustomer?.data?.data?.user.services.map( (service, id) => (
						<Link key={id} to={service?.templates?.slug} className="col-sm-12 col-lg-3">
							<Card className={'cursor-pointer shadow-3d-primary shadow-3d-hover'}><CardBody><TemplateContent title={service?.templates?.name} description={service?.templates?.description} /></CardBody></Card>
						</Link>
						)) }
					</div>
				</div>
			</div>
		</Page>
	)
}

export default CustomerTemplates