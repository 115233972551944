import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import { getCitiesWeather } from '../../fetch/weather.fetch';
import './templates.scss'
import { useDispatch, useSelector } from 'react-redux';
import { setWeather } from "../../store/weather/weahter.action"
import moment from 'moment';
import { getCustomer } from '../../fetch/customers.fetch';
import { removeUser } from '../../store/user/user.action';
import { useNavigate, useParams } from 'react-router-dom';

const Slider = ({children, slideIndex, setSlideIndex, init}) => {
	const channel = new BroadcastChannel('record');
	const user = useSelector(state => state.user)
	const [ pageIsLoading, setPageIsLoading ] = useState(true);
	
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// ----------------------------------------------------------
	// Fetching user information
	// ----------------------------------------------------------
	const queryCustomer = useQuery({
		queryKey: ['customer', user.info.id], 
		queryFn: () => getCustomer(user.token, user.info.id),
		onSuccess: (res) => {
			if ( res.status === 401 )
				dispatch(removeUser());
		},
		onError: (error) => {
			if ( error.response.status === 401 )
				dispatch(removeUser());
			else navigate('/customers')
		}
	});

	// Check if user has this teplate in their service if not redirect back to /templates
	useEffect( () => {
		console.log(queryCustomer?.data?.data?.user.isAdmin);
		
		if (queryCustomer.isSuccess && !queryCustomer?.data?.data?.user?.isAdmin)
		{
			var redirect = true;
			queryCustomer?.data?.data.user?.services.find( service => {
				if ( service.templates.slug === window.location.pathname.split("/")[2] )
				{
					redirect = false;
				}
			})

			if ( redirect )
				navigate('/templates');
		}
	}, [queryCustomer?.data?.data?.user])


	// ----------------------------------------------------------
	// Fetching the weather information
	// ----------------------------------------------------------
	const weatherQuery = useQuery({
		queryKey: ['weathers'],
		queryFn: () => getCitiesWeather(user.token),
		staleTime: 1000,
		onSuccess: (res) => {
			dispatch(setWeather(res.data));
		}
	});


	useEffect( () => {
		setTimeout( () => {
			setPageIsLoading(false)
		}, 5000 )
	}, [] )


	useEffect( () => {
		if ( slideIndex !== undefined )
		{
			// all slider items
			var items = document.querySelector('.slider').childNodes;

			// set active class to the active slider item
			items[slideIndex].classList.add('active');

			// get the item duration / can be space instead time!
			var itemDuration = items[slideIndex].getAttribute('data-duration') ?? 0;

			var duration = itemDuration * 1000;

			// get the item audio data
			var pauseAudio = items[slideIndex].getAttribute('data-pauseaudio') ?? 0;

			const playVideo = (index) => {
				let _video = items[index].querySelector('video#transition'); 
				if ( _video !== null ) { _video.play() }
			}

			const playAudio = (index) => {
				let audio = items[index].querySelector('audio'); 
				if ( audio !== null ) { audio.play() }
			}

			const stopVideo = (index) => {
				let _video = items[index].querySelector('video#transition'); 
				if ( _video !== null ) { _video.pause() }
			}

			const stopAudio = () => {
				let audio = document.querySelector('audio'); 
				if ( audio !== null ) { audio.pause() }
			}

			// check first slide
			if ( slideIndex === 0 )
			{
				playVideo(slideIndex);
				playAudio(slideIndex);
			}

			if ( slideIndex > 0 )
			{
				stopVideo(slideIndex-1);

				playVideo(slideIndex);
				playAudio(slideIndex);
			}

			if ( pauseAudio ) 
				stopAudio();

			// set timeout with duration and after that change the active item
			if ( itemDuration !== 'space' ) {
				if ( slideIndex+1 < items.length ) {
					setTimeout( () => {
						items[slideIndex].classList.remove('active');
						setSlideIndex(slideIndex+1);
					}, duration);
				}
			} else {
				document.onkeydown = (e) => {
					e = e || window.event;
					// space for going forward
					if (e.keyCode === 32)
					{
						if ( slideIndex+1 === items.length )
						{
							return false;
						}

						items[slideIndex].classList.remove('active');
						setSlideIndex(slideIndex+1);
					}

					// arrow left for going back
					if (e.keyCode === 37)
					{
						if ( slideIndex === 0 )
						{
							return false;
						}

						items[slideIndex].classList.remove('active');
						setSlideIndex(slideIndex-1);
					}
				}
			}

			// detecting last slide and stoping the recording
			if ( slideIndex+1 === items.length )
			{
				setTimeout( () => {
					channel.postMessage('stop');
				}, duration);
			}
		}
	}, [slideIndex]);
	

	return (
		<>
			{ weatherQuery.isSuccess ? <div className="slider" index={slideIndex}>{ pageIsLoading ? <h1>Се спремаат податоците...</h1> : children }</div> : '' }
		</>
	)
}

export default Slider 